export const IS_SERVER = typeof window === 'undefined'
export const URL_REGEX_NO_PROTOCOL =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
export const ALLOWED_ORIGINS = [
  'cogsworth.dev',
  'cogsworth.com',
  'cogsworth.com.au',
  'cogsworthstaging.com',
  'upvio.dev',
  'upvio.com',
  'upviostaging.com',
]

// Local storage key names
export const LSK_USER = 'upvio.user'
export const LSK_CALENDAR = 'upvio.calendar'
export const LSK_LOG_LEVEL = 'upvio.logLevel'

// Environment Variables
export const APP_ENV = process.env.NEXT_PUBLIC_APP_ENV || 'development'

export const DEFAULT_LIST_DATE_FORMAT = 'd MMM yyyy'

export const DEFAULT_CURRENCY = 'USD'

export const DATE_FORMATS = {
  TIME: [
    { value: 'h:mm', label: '3:30' },
    { value: 'hh:mm', label: '03:30' },
    { value: 'h:mma', label: '3:30pm' },
    { value: 'hh:mm A', label: '03:30 PM' },
    { value: 'HH:mm', label: '15:30' },
  ],
  DATE: [
    { value: 'dddd, MMMM Do, YYYY', label: 'Monday, January 1st, 2020' },
    { value: 'dddd, MMMM D, YYYY', label: 'Monday, January 1, 2020' },
    { value: 'dddd Do, MMMM YYYY', label: 'Monday 1st, January 2020' },
    { value: 'dddd D, MMMM YYYY', label: 'Monday 1, January 2020' },
    { value: 'dddd, D. MMMM YYYY', label: 'Monday, 1. January 2020' },
    { value: 'MMMM Do, YYYY', label: 'January 21st, 2020' },
    { value: 'MMMM D, YYYY', label: 'January 21, 2020' },
    { value: 'DD/MM/YYYY', label: '21/01/2020' },
    { value: 'MM/DD/YYYY', label: '01/21/2020' },
    { value: 'YYYY/MM/DD', label: '2020/01/21' },
  ],
  TIME_STANDARD: [
    { value: 'GMT', label: 'GMT' },
    { value: 'UTC', label: 'UTC' },
  ],
}

export const DEFAULT_BUSINESS_AVAILABILITY_LIMIT_DAYS = 90
export const DEFAULT_NEW_EVENT_DURATION = 60 * 60 * 1000 // 1 hour in milliseconds

/**
 * Data shown in billing [features comparison modal]
 * updated using PricingUpgradeTable from google sheet 16/04/24
 * https://docs.google.com/spreadsheets/d/163zzY6yHZsrQ2lnVw8H6Td7-NOx3UOGSM2MZLPdFpzQ/edit?pli=1#gid=639581105
 */
export const COMPARISON_FEATURES_DATA = {
  head: ['Scheduler & Forms', 'Full Practice', 'Enterprise'],
  body: {
    Scheduling: {
      'Unlimited appointments': [true, true, true],
      'Customizable booking pages': [true, true, true],
      'Booking pages for the business': [true, true, true],
      'Booking pages for specific staff': [true, true, true],
      'Booking pages for specific services': [true, true, true],
      'Booking pages for specific service categories': [true, true, true],
      'Embed booking page into your site': [true, true, true],
      'Multi calendar sync': [true, true, true],
      'Time Slots - fastest way to share availability': [true, true, true],
      'Group bookings': [true, true, true],
      'Multi-staff appointments': [false, true, true],
      'Ad-hoc appointments': [true, true, true],
      'Recurring appointments': [true, true, true],
      'Selective booking dates': [true, true, true],
      'Buffer, gaps and limits': [true, true, true],
      'Customizable rescheduling forms': [true, true, true],
      'Create multiple businesses': [true, true, true],
      'Multiple locations & facilities': [true, true, true],
      'Virtual & physical locations': [true, true, true],
      'Pair appointments with treatment rooms': [true, true, true],
      'Simple & in-depth dashboards': [true, true, true],
      'Custom service': [true, true, true],
      'Booking with request approval': [true, true, true],
      'Advanced calendar filters': [true, true, true],
      'Multi-staff calendar view': [false, true, true],
      'Paperless intake': [true, true, true],
    },
    Communications: {
      'Custom automated email notifications': [
        'Unlimited',
        'Unlimited',
        'Unlimited',
      ],
      'Custom automated SMS notifications': [
        '100 credits then $12 per extra 100',
        '100 credits then $12 per extra 100',
        'Custom',
      ],
      'Build custom communication journeys': [true, true, true],
      'Post appointment follow-ups': [true, true, true],
      'Custom reminders, rescheduling notifications': [true, true, true],
      'Customer review follow-up notifications': [true, true, true],
      'Pre-built email and SMS templates': [true, true, true],
      'Chat Messenger': [true, true, true],
      'Chat Messenger during video calls': [true, true, true],
      'Virtual waiting room for ad-hoc communication': [true, true, true],
      'Do-it-for-you notification migration & setup': [false, false, true],
    },
    'Team Management': {
      'Multi-staff calendar sync': [true, true, true],
      'Different team roles and rights': [true, true, true],
      'Set custom rules for staff': [true, true, true],
      'Create staff profiles and biographies': [true, true, true],
      'Add individual users': [true, true, true],
    },
    'Video Consultations': {
      'Unlimited calls': [false, true, true],
      'Secure video rooms': [false, true, true],
      'HD and mirror video options': [false, true, true],
      'Shareable video room link': [false, true, true],
      'Virtual patient waiting room': [false, true, true],
      'Chat during video calls': [false, true, true],
      'One-click entry for patients': [false, true, true],
      'Private & group calls': [false, true, true],
      'Picture-in-Picture video calls across app': [false, true, true],
      'Floating video screen across app': [false, true, true],
      'Share screens': [false, true, true],
    },
    Payments: {
      'Online payments via Stripe': [true, true, true],
      'Full and part payments upfront': [true, true, true],
    },
    'Patient Records': {
      'Store patient records': [
        'Unlimited patient records',
        'Unlimited patient records',
        'Unlimited patient records',
      ],
      'Patient and data import': [true, true, true],
    },
    Forms: {
      'Paperless intake forms': [true, true, true],
      'Easy form builder': [true, true, true],
      'Customizable forms': [true, true, true],
      'Brand your own forms, logo and colours': [true, true, true],
      'Pre-designed, customizable templates': [true, true, true],
      'Patient & contact signatures': [true, true, true],
      'Booking widget insertable into forms': [true, true, true],
      'Insert payment options': [true, true, true],
      'Forms response inbox': [true, true, true],
      'Unlimited form submissions': [true, true, true],
      'Customizable rescheduling forms': [true, true, true],
      'Forms Included': ['Unlimited ', 'Unlimited ', 'Custom'],
      'Do-it-for-you forms migration & setup': [false, false, true],
    },
    Notifications: {
      'Custom automated email notifications': [
        'Unlimited',
        'Unlimited',
        'Unlimited',
      ],
      'Custom automated SMS email notifications': [
        '100 credits then $12 per extra 100',
        '100 credits then $12 per extra 100',
        'Custom',
      ],
      'Usage-based SMS': [true, true, true],
      'Post appointment follow-ups': [true, true, true],
    },
    'Compliance & Account Support': {
      'Fully HIPAA-compliant': [true, true, true],
      'We issue BAAs': [true, true, true],
      'Fully GDPR-compliant': [true, true, true],
      '24/7 Live Customer Support': [true, true, true],
      'Disaster Recovery': [true, true, true],
      'API Program': [false, false, true],
      'Sandbox and testing environment': [false, false, true],
      'Developer-to-developer': [true, true, true],
    },
    FaceVitals: {
      'Remote vitals scan via phone or other web cameras': [
        'from $8.50 per scan',
        'from $8.50 per scan',
        'from $8.50 per scan',
      ],
      'Immediate results report for patients & staff': [true, true, true],
      'Scans during video calls': [true, true, true],
      'Scans during digital intake process': [true, true, true],
      'Fully HIPAA-compliant': [true, true, true],
      'Integrated in Upvio practice management platform': [true, true, true],
    },
  },
}

export const MEDIA_BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
}
